
  
  @font-face {
    font-family: "NotoSansKR-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("./NotoSansKR-Bold.woff") format("woff");
  }
  
  @font-face {
    font-family: "Pretendard-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("./Pretendard-Bold.woff") format("woff");
  }
  
  @font-face {
    font-family: "Pretendard-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("./Pretendard-Medium.woff") format("woff");
  }
  
  @font-face {
    font-family: "Pretendard-Regular";
    font-style: normal;
    font-weight: 400;
    src: url("./Pretendard-Regular.woff") format("woff");
  }
  @font-face {
    font-family: "Pretendard-Thin";
    font-style: normal;
    font-weight: 100;
    src: url("./Pretendard-Thin.woff") format("woff");
  }